import { LoadingSpinner } from "components/LoadingSpinner";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Button, Icon, Menu, Sidebar, Table } from "semantic-ui-react";
import styled from 'styled-components';
import { getAttachment } from "../../../services/API";
import { apiAttachmentUrl, getGcpProjectId } from "../../../services/CONSTANTS";
import { convertDateToLocal } from "../../../services/allMessagesBodyService";
import { StyledHeader } from "./style/filter-component-styling";
import { TableStyles } from "./style/table-component-styling";

const DetailsSideBarStyle = styled.div`
  .sidebar {
    background-color: #fff !important;
    padding: 30px;

    .element {
      &:first-child {
        padding-top: 20px;
      }

      &.one-row {
        display: flex;
        justify-content: space-between;

        div:last-child {
          padding-left: 15px;
        }
      }
      padding: 5px 0;
    }

    .ui.button.close-button {
      position: absolute;
      right: 15px;
      top: 15px;
      background: transparent;
      padding: 0;
      color: #084d86;
    }

    .ui.button {
      background: transparent;
      padding: 5px 0;
      display: block;
      color: #1DAFED;
    }
  }

  .ui.right.sidebar {
    width: fit-content;
    min-width: 260px;
  }

  .download-attachment {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .expired {
    color: rgb(182, 15, 54);
  }
`;

export const DetailsSideBar = ({data, setDetails, loading}) => {

    const closeDetail = () => {
        setDetails({})
    }

    const downloadAttachment = (id) => {
        const url = apiAttachmentUrl(id);
        getAttachment(url);
    }

    const isDateExpired = (attachmentDate) => {
        const dateDifference = new Date() - new Date(attachmentDate);
        return (dateDifference > 0)
    }

    const buildGcpRawLogsUrl = (smtpMessageId) => {
        // @ts-ignore
        return `https://console.cloud.google.com/logs/query;query=jsonPayload.message: "${smtpMessageId.replace(/"/g, '%5C"')}" OR textPayload: "${smtpMessageId.replace(/"/g, '%5C"')}";duration=PT336H;summaryFields=resource%2Flabels%2Fcontainer_name:false:32:beginning?project=${getGcpProjectId()}`;
    }

    const showMessageDetails = () => {
        return (
            <div>
                <Button onClick={closeDetail} className="close-button"><Icon fitted name='close' /></Button>
                <StyledHeader
                    as='h6'>{data?.outbound ? "Outbound " : data?.inbound ? "Inbound " : ""} {data?.inputMessageType === "smtp" ? "SMTP " : "XD"} Message</StyledHeader>
                {data?.dispositions?.length ? <TableStyles sortable celled basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Disposition
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Date
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data?.dispositions?.map(({
                            id, messageAttributeValue, createTimestamp
                        }) => (<Table.Row key={id}>
                            <Table.Cell>{messageAttributeValue}</Table.Cell>
                            <Table.Cell>
                                <div className='days'>
                                    <Moment format="YYYY-MM-DD">
                                        {convertDateToLocal(createTimestamp)}
                                    </Moment>
                                </div>
                                <div className='hours'>
                                    <Moment format="hh:mm:ss:SSS">
                                        {convertDateToLocal(createTimestamp)}
                                    </Moment>
                                </div>
                            </Table.Cell></Table.Row>))}
                    </Table.Body>
                </TableStyles> : ""}
                {data?.states?.length ? <TableStyles sortable celled basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                State
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Date
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data?.states?.map(({
                            createTimestamp, state
                        }, index) => (<Table.Row key={index}>
                            <Table.Cell>{state}</Table.Cell>
                            <Table.Cell>
                                <div className='days'>
                                    <Moment format="YYYY-MM-DD">
                                        {convertDateToLocal(createTimestamp)}
                                    </Moment>
                                </div>
                                <div className='hours'>
                                    <Moment format="hh:mm:ss.SSS">
                                        {convertDateToLocal(createTimestamp)}
                                    </Moment>
                                </div>
                            </Table.Cell></Table.Row>))}
                    </Table.Body>
                </TableStyles> : ""}
                {data?.error ? <TableStyles sortable celled basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Code
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Reason
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row key={1}>
                            <Table.Cell>{data?.error?.errorCode}</Table.Cell>
                            <Table.Cell>{data?.error?.failureReason}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </TableStyles> : ""}
                <div className="element one-row">
                    <div>
                        <StyledHeader as='h6'>asmEnable</StyledHeader>
                        <p>{data?.asmEnabled ? "Yes" : "No"} </p>
                    </div>
                    {data?.messageDirection !== "INBOUND" &&
                        <div>
                            <StyledHeader as='h6'>ASM Domain Status</StyledHeader>
                            <p>{data?.isAsmDispatchedSent ? "ASM will be sent" : "ASM will not be sent"} </p>
                        </div>}
                </div>
                {data?.attachments?.length ? <TableStyles sortable celled basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Expire Time
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Download
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data?.attachments?.map(({
                            originalFilename, id, expireTime
                        }) => (<Table.Row key={id}>
                            <Table.Cell><p>{originalFilename}</p></Table.Cell>
                            <Table.Cell className={isDateExpired(expireTime) ? "expired" : ""}>
                                <div className='days'>
                                    <Moment format="YYYY-MM-DD hh:mm:ss">
                                        {convertDateToLocal(expireTime)}
                                    </Moment>
                                </div>
                            </Table.Cell>
                            <Table.Cell className="download-attachment" disabled={isDateExpired(expireTime)}
                                onClick={() => downloadAttachment(id)}><Icon fitted
                                    name='download' /></Table.Cell>
                        </Table.Row>
                        ))}
                    </Table.Body>
                </TableStyles> : ""}

                <Button
                    onClick={() => window.open(buildGcpRawLogsUrl(data.smtpMessageId), '_blank')}>
                    GCP raw logs  <Icon fitted name='external' />
                </Button>

                {/*<div className="element">*/}
                {/*    <StyledHeader as='h6'>Msg Type</StyledHeader>*/}
                {/*    <p>Type</p>*/}
                {/*</div>*/}
                {/*<div className="element">*/}
                {/*    <StyledHeader as='h6'>Edge System</StyledHeader>*/}
                {/*    <p>System</p>*/}
                {/*</div>*/}
                {/*<div className="element">*/}
                {/*    <StyledHeader as='h6'>Receiving HISP</StyledHeader>*/}
                {/*    <p>HISP</p>*/}
                {/*</div>*/}
                {/*<div className="element">*/}
                {/*    <Button>View Tomcat <Icon fitted name='caret right' /></Button>*/}
                {/*    <Button>View James <Icon fitted name='caret right' /></Button>*/}
                {/*</div>*/}
            </div>
        );
    }

    return (
        <DetailsSideBarStyle>
            <Sidebar
                as={Menu}
                animation='overlay'
                direction='right'
                inverted
                vertical
                visible={loading || !!data.id}
            >
                {loading ? <LoadingSpinner /> : showMessageDetails()}
            </Sidebar>
        </DetailsSideBarStyle>
    );

    
};

DetailsSideBar.propTypes = {
    data: PropTypes.object.isRequired,
    setDetails: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}
